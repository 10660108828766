const allCourses = [
  {
    title: "UI|UX (Product) design",
    duration: "3 Months",
    image: "UI-UX.jpeg",
    courseOutline: [
      { id: "1", module: "Introduction to Notion" },
      { id: "2", module: "Basic principles of Design" },
      { id: "3", module: "Introduction to figma" },
      { id: "4", module: "Empathy in Design" },
      { id: "5", module: "Interpreting a Project Brief" },
      { id: "6", module: "Initiation of Case Study" },
      { id: "7", module: "Designing a User flow/User Journey" },
      { id: "8", module: "Designing Wireframe" },
      { id: "9", module: "Design System" },
      { id: "10", module: "Style Guild" },
      { id: "11", module: "Colors" },
      { id: "12", module: "Typography" },
      { id: "13", module: "Iconography" },
      { id: "14", module: "Grid System" },
      { id: "15", module: "Spacing" },
      { id: "16", module: "Text fields" },
      { id: "17", module: "Rapid Testing in Design" },
      { id: "18", module: "Auto Layout & Responsiveness" },
      { id: "19", module: "Fundamentals of UX" },
    ],
    price: 400000,
    info: {
      title: "UI/UX Designer",
      subTitle: "",
    },
    tools: [
      "notion-logo.png",
      "figma-logo.png",
      "adobeXD-logo.png",
      "google-logo.png",
      "purple-logo.png",
      "Illustrator-logo.png",
      "yellow-logo.png",
      "baloon-logo.png",
    ],
  },
  {
    title: "Full Stack Software Development",
    duration: "8 Months",
    image: "software-Engineering.jpeg",
    courseOutline: [
      { id: "1", module: "Web Development with HTML & CSS" },
      { id: "2", module: "Advanced HTML & CSS" },
      { id: "3", module: "Flexbox, Grid, & Bootstrap" },
      { id: "4", module: "JavaScript for Front-End" },
      { id: "5", module: "Web Developer Tools: Git & Coding Tips" },
      { id: "6", module: "JavaScript Programming Fundamentals" },
      { id: "7", module: "React Development" },
      { id: "8", module: "JavaScript Development with Node.js" },
      { id: "9", module: "JavaScript Development with Express.js" },
      { id: "10", module: "CI/CD and Deployment of apps to servers" },
      { id: "11", module: "NoSQL Databases with MongoDB" },
      { id: "12", module: "Web Development Industry & Portfolio" },
      { id: "13", module: "Advance Node js for Backend Development" },
      { id: "14", module: "Laravel for Backend Development" },
      { id: "15", module: "Python for  Web Development with Django" },
    ],
    price: 600000,
    info: {
      title: "Full-Stack Software Engineer",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Data Science & Machine Learning",
    duration: "6 Months",
    image: "dat- science.jpeg",
    courseOutline: [
      { id: "1", module: "Module 1 : Preparatory Sessions – Python & Linux" },
      { id: "2", module: "Module 2 : GIT" },
      {
        id: "3",
        module: "Module 3 : Python with Data Science/ Jupyter Notebook",
      },
      { id: "4", module: "Module 4 : Advanced Statistics" },
      {
        id: "5",
        module: "Module 5 : Machine Learning & Prediction Algorithms",
      },
      { id: "6", module: "Module 6 : Data Science at Scale with PySpark" },
      { id: "7", module: "Module 7 : AI & Deep Learning using TensorFlow" },
      {
        id: "8",
        module: "Module 8 : Deploying Machine Learning Models on Cloud (MLOps)",
      },
      { id: "9", module: "Module 9 : Data Visualization with Tableau" },
      { id: "10", module: "Module 10 : Data Science Capstone Project" },
      { id: "11", module: "Module 11 : Data Analysis with MS Excel, Power BI, SPSS and SQL" },
      { id: "12", module: "Module 12 : Data Wrangling with SQL" },
      {
        id: "13",
        module: "Module 13 : Natural Language Processing and its Applications",
      },
      {
        id: "14",
        module: "Module 13 : Natural Language Processing and its Applications",
      },
    ],
    price: 500000,
    info: {
      title: "Data Scientist",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Digital Marketing",
    duration: "2 Months",
    image: "digital-marketing.jpeg",
    courseOutline: [
      { id: "", module: "Course Outline for Digital Marketing" },
      { id: "", module: "Introduction to Digital Marketing" },
      { id: "", module: "SEO Overview" },
      { id: "", module: "Social Media Marketing -Organic" },
      { id: "", module: "Facebook & Instagram Ads" },
      { id: "", module: "Ads Budgeting" },
      { id: "", module: "Email and SMS Marketing" },
    ],
    price: 300000,
    info: {
      title: "Digital Marketer",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Accounting Software",
    duration: "3 Months",
    image: "account2.jpeg",
    courseOutline: [],
    price: 100000,
    info: {
      title: "Digital Accountant",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Web Design",
    duration: "3 Months",
    image: "web-design.webp",
    courseOutline: [
      { id: 1, module: "HTML and CSS" },
      { id: 2, module: "Bootstrap" },
      { id: 3, module: "javascript" },
      { id: 4, module: "Wordpress" },
      { id: 5, module: "ReactJs" },
      { id: 6, module: "Vuejs" },
      { id: 7, module: "Vuetify" },
      { id: 8, module: "NuxtJs" },
      { id: 9, module: "NextJs" },
      { id: 10, module: "CI/CD Tools" },
    ],
    price: 300000,
    info: {
      title: "Web Designer",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Web Development",
    duration: "3 Months",
    image: "webdev.webp",
    courseOutline: [
      { id: 1, module: "PHP" },
      { id: 2, module: "Laravel" },
      { id: 3, module: "JaveScript" },
      { id: 4, module: "NodeJs" },
      { id: 5, module: "Python/Django" },
      { id: 6, module: "Firebase" },
    ],
    price: 300000,
    info: {
      title: "Web Developer",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "DevOps",
    duration: "3 Months",
    image: "devops.webp",
    courseOutline: [
      { id: 1, module: "Version Control with Git" },
      { id: 2, module: "Git, Jenkins and Maven Integration" },
      { id: 3, module: "Continuous Integration using Jenkins" },
      { id: 4, module: "Configuration Management using Ansible" },
      { id: 5, module: "Containerization using Docker Part - I" },
      { id: 6, module: "Containerization using Docker Part - II" },
      { id: 7, module: "Orchestration using Kubernetes Part - I" },
      { id: 8, module: "Orchestration using Kubernetes Part - II" },
      { id: 9, module: "Monitoring using Prometheus and Grafana" },
      { id: 10, module: "Provisioning using Terraform Part - I" },
      { id: 11, module: "Provisioning using Terraform Part - II" },
      { id: 12, module: "Selenium (Self-Paced)" },
      { id: 13, module: "Nagios (Self-Paced)" },
      { id: 14, module: "DevOps on Cloud (Self-Paced)" },
      { id: 15, module: "AWS EC2 and IAM (Self-Paced)" },
    ],
    price: 1200000,
    info: {
      title: "DevOps Engineer",
      subTitle: "",
    },
    tools: [
      "git-bash.svg",
      "jenkins.png",
      "prometheus.png",
      "ansible.png",
      "grafana.webp",
      "docker.png",
      "kubernetes.png",
      "maven.png",
      "terraform.png"
    ],
  },
  // {
  //   title: "IOT|Embedded Engineering",
  //   duration: "3 Months",
  //   image: "embedded-system.jpg",
  //   courseOutline: [
  //     { id: 1, module: "Arduino" },
  //     { id: 2, module: "RaspberryPi" },
  //     { id: 3, module: "Basic Electronics" },
  //     { id: 4, module: "Microcontrollers" },
  //     { id: 5, module: "IDEs used in programming the controllers" },
  //     { id: 6, module: "Programming controllers usinc C and C++" },
  //     { id: 7, module: "Internet of things (IOT)" },
  //   ],
  //   price: 200000,
  //   info: {
  //     title: "IOT Engineer",
  //     subTitle: "",
  //   },
  //   tools: [],
  // },
  {
    title: "Cloud Computing",
    duration: "3 Months",
    image: "cloud-computing.jpeg",
    courseOutline: [],
    price: 1000000,
    info: {
      title: "Cloud Computing Engineer",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Computer litracy",
    duration: "2 Months",
    image: "computer-basics.webp",
    courseOutline: [
      { id: "", module: "Basic Terms" },
      { id: "", module: "Types of Computers" },
      { id: "", module: "Anatomy of a PC" },
      { id: "", module: "How a PC Works" },
      { id: "", module: "CPU and Memory" },
      { id: "", module: "Input Devices" },
      { id: "", module: "Output Devices" },
      { id: "", module: "Secondary Storage Devices" },
      { id: "", module: "Operating Systems and Applications" },
      { id: "", module: "How is Software Built?" },
      { id: "", module: "Types of Software" },
      { id: "", module: "Legal Issues" },
      { id: "", module: "Office Tools" },
    ],
    price: 70000,
    info: {
      title: "Computer Professional",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Video Editing & Animation",
    duration: "3 Months",
    image: "video-animation.jpeg",
    courseOutline: [
      { id: "", module: "Getting to know After Effect" },
      { id: "", module: "Tools" },
      { id: "", module: "Work with composition" },
      { id: "", module: "Keyframe animations" },
      { id: "", module: "Create & Animate text" },
      { id: "", module: "Build Custom Transitions " },
      { id: "", module: "Visual Effect" },
      { id: "", module: "Animated Background " },
      { id: "", module: "Build Layers & Compostion " },
      { id: "", module: "Work with  2D & 3D" },
      { id: "", module: "Properties " },
      { id: "", module: "Photoshop & Illlustrator in after effect" },
      { id: "", module: "Repair Video" },
      { id: "", module: "Color Correction " },
      { id: "", module: "Cloning & Green Screen " },
      { id: "", module: "Expression " },
      { id: "", module: "Render" },
    ],
    price: 500000,
    info: {
      title: "Video Editor",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Automation: Robotics | IOT",
    duration: "8 Months",
    image: "robotics-and-automation.png",
    courseOutline: [
      { id: "", module: "Computational Geometry" },
      { id: "", module: "Computer-aided Manufacturing" },
      { id: "", module: "Industrial Robotics" },
      { id: "", module: "AI/Machine Learning" },
      { id: "", module: "Automation" },
      { id: "", module: "Biocybernetics" },
      { id: "", module: "Digital Electronics And Microprocessors" },
      { id: "", module: "Design And Control" },
      { id: "", module: "Robot Manipulators" },
      { id: "", module: "Air Traffic And Management Systems" },
      { id: "", module: "Artificial Intelligence" },
      { id: "", module: "Signal Processing" },
      { id: "", module: "Medical Robotics" },
      { id: "", module: "Robot Motion Planning" },
      { id: "", module: "Computer Integrated Manufacturing System " },
    ],
    price: 1200000,
    info: {
      title: "Automation: Robotics | IOT",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Advanced Excel",
    duration: "3 Months",
    image: "excel.png",
    courseOutline: [
      { id: "", module: "Customizing common options in Excel" },
      { id: "", module: "Absolute and relative cells" },
      { id: "", module: "Protecting and unprotecting worksheets and cells" },
      { id: "", module: "Advanced Excel Course - Working with Functions" },
      { id: "", module: "Writing conditional expressions (using IF)" },
      { id: "", module: "Using logical functions (AND, OR, NOT)" },
      {
        id: "",
        module:
          "Using lookup and reference functions (VLOOKUP, HLOOKUP, MATCH, INDEX)",
      },
      { id: "", module: "VlookUP with Exact Match, Approximate Match" },
      { id: "", module: "Nested VlookUP with Exact Match" },
      { id: "", module: "VlookUP with Tables, Dynamic Ranges" },
      { id: "", module: "Nested VlookUP with Exact Match " },
      {
        id: "",
        module: "Using VLookUP to consolidate Data from Multiple Sheets",
      },
      { id: "", module: "Advanced Excel Course - Data Validations" },
      { id: "", module: "Advanced Excel Course - Working with Templates" },
      { id: "", module: "Advanced Excel Course - Sorting and Filtering Data " },
      { id: "", module: "Advanced Excel Course - Working with Reports" },
      { id: "", module: "Creating subtotals" },
      { id: "", module: "Creating Pivot tables" },
      { id: "", module: "Advanced Excel Course - More Functions" },
      { id: "", module: "Text functions" },
      { id: "", module: "Power Functions (CountIf, CountIFS, SumIF, SumIfS) " },
      { id: "", module: "Advanced Excel Course – Formatting" },
      {
        id: "",
        module:
          "Using conditional formatting option for rows, columns and cells ",
      },
      { id: "", module: "Advanced Excel Course – What If Analysis" },
      { id: "", module: "Scenario Manager" },
      { id: "", module: "Advanced Excel Course – Charts" },
      { id: "", module: "Using 3D Graphs" },
      { id: "", module: "Using Bar and Line Chart together " },
      { id: "", module: "Advanced Excel Course - New Features of Excel" },
      {
        id: "",
        module:
          " Spark lines, Inline Charts, data Charts Overview of all the new features",
      },
    ],
    price: 100000,
    info: {
      title: "Excel software Proffessional",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Cyber Security|Ethical Hacking",
    duration: "3 Months",
    image: "cyber-security-illustration-free-vector.jpeg",
    courseOutline: [
      { id: "1", module: "Fundamentals of Cybersecurity" },
      { id: "1", module: "Network Security" },
      { id: "1", module: "Database Security	" },
      { id: "1", module: "Server Security" },
      { id: "1", module: "Web Application Security	" },
      { id: "1", module: "IT Security Compliance" },
      { id: "1", module: "Android Penetration Testing	" },
      { id: "1", module: "Computer Forensics" },
      { id: "1", module: "Python Programming	" },
      { id: "1", module: "Project work" },
    ],
    price: 700000,
    info: {
      title: "Ethical Hacker",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Matlab",
    duration: "3 Months",
    image: "matlab.jpg",
    courseOutline: [
      { id: "", module: "Desktop,Variables, and Data Types" },
      { id: "", module: "Introduction to MATLAB" },
      { id: "", module: "Creating Variables Some Useful MATLAB Functions" },
      { id: "", module: "Data Types" },
      { id: "", module: "Script Files " },
      { id: "", module: "Plotting Videos" },
      { id: "", module: "Introduction to Arrays Graphing Exercises" },
      { id: "", module: "Graphing Functions Using MATLAB" },
      { id: "", module: "Good Programming Practices" },
      { id: "", module: "Planning Your Code Good Programming Practices" },
      { id: "", module: "Creating Your Code" },
      { id: "", module: "Input and Output Statements" },
      { id: "", module: "Conditional Statements:Logical Operators" },
      { id: "", module: "Conditional Statements: if, else, and elseif" },
      { id: "", module: "Conditional Structures: Switch" },
      { id: "", module: "Introduction to Loops Repetition Structure" },
      { id: "", module: "For Loops Repetition Structure" },
      { id: "", module: "While Loops" },
      { id: "", module: "Nested Loops and the Break Statement" },
      { id: "", module: "Arrays" },
      { id: "", module: "Array Functions " },
      { id: "", module: "Useful Functions for Arrays" },
    ],
    price: 50000,
    info: {
      title: "Matlab Developer",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Graphics Design",
    duration: "2 Months",
    image: "Graphic-Design.png",
    courseOutline: [],
    price: 300000,
    info: {
      title: "Graphics Designer",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Product Management",
    duration: "3 Months",
    image: "product-manager.webp",
    courseOutline: [],
    price: 400000,
    info: {
      title: "Product Manager",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Software Engineering",
    duration: "10 Months",
    image: "engineer.avif",
    courseOutline: [],
    price: 800000,
    info: {
      title: "Software Designer",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "Mobile App Development",
    duration: "6 Months",
    image: "software-Engineering.jpeg",
    courseOutline: [
      { id: "1", module: "Introduction to mobile application development" },
      { id: "1", module: "History of application development" },
      {
        id: "1",
        module: "Theory of application development for mobile platforms",
      },
      {
        id: "1",
        module: "Understanding basics of application development software",
      },
      {
        id: "1",
        module:
          "Understanding smartphone, tablet and laptop application development",
      },
      { id: "1", module: "Android Application development" },
      { id: "1", module: "History of Android OS" },
      { id: "1", module: "Understanding the Android OS platform" },
      { id: "1", module: "Architecture of Android-based devices" },
      {
        id: "1",
        module: "Cross Platform Development with ReactNative and Flutter",
      },
      { id: "1", module: "Understanding basics of Java and Kotlin" },
      { id: "1", module: "Architecture of Android-based devices" },
      { id: "1", module: "Building basic applications with Java and Kotlin" },
      { id: "1", module: "Understanding Android application structure" },
      { id: "1", module: "Designing User Interface" },
      { id: "1", module: "Updating Android applications" },
      { id: "1", module: "iOS Application development" },
      { id: "1", module: "History of iOS platform" },
      { id: "1", module: "Architecture of Apple devices" },
      { id: "1", module: "Understanding basics of Swift" },
      { id: "1", module: "Application development using Swift" },
      { id: "1", module: "Understanding basics of Objective – C" },
      { id: "1", module: "App development using Objective – C" },
    ],
    price: 300000,
    info: {
      title: "Mobile App Developer",
      subTitle: "",
    },
    tools: [],
  },
  {
    title: "BlockChain Development",
    duration: "3 Months",
    image: "Blockchain.png",
    courseOutline: [
      { id: "1", module: "Blockchain Fundamentals" },
      { id: "1", module: "Blockchain Basics" },
      { id: "1", module: "Managing Blockchain Transactions" },
      { id: "1", module: "Bitcoin core Testnet" },
      { id: "1", module: "Blockchain Data" },
      { id: "1", module: "Private Blockchains" },
      { id: "1", module: "Digital Assets on Blockchain" },
      { id: "1", module: "Ethereum Smart Contracts, Tokens and Dapps" },
      { id: "1", module: "Ethereum Fundamentals and Development Tools" },
      { id: "1", module: "Smart Contracts with Solidity" },
      { id: "1", module: "Ethereum APP" },
      { id: "1", module: "Blockchain Architecture" },
      { id: "1", module: "Planning Blockchain Solutions" },
      { id: "1", module: "Privacy" },
      { id: "1", module: "Security & Maintenance" },
      { id: "1", module: "Distributed file system" },
      { id: "1", module: "Dapp with autonomous smart contracts and oracles" },
      {
        id: "1",
        module: "Multiparty control and payments with smart contracts",
      },
      { id: "1", module: "ORACLES" },
      { id: "1", module: "Handing smart contract payments" },
    ],
    price: 1200000,
    info: {
      title: "Blockchain Developer",
      subTitle: "",
    },
    tools: [],
  },
];

export default allCourses;
